import { observer } from "mobx-react";
import BaseView from "wes_shell_app/base-view";
import ManagementSUDataTable from "../components/management-su-data-table";
import { ManagementSuDataTableFilter } from "../components/management-su-data-table-filter";

const ManagementView = observer(() => {
  return (
    <BaseView>
      <ManagementSuDataTableFilter />
      <ManagementSUDataTable />
    </BaseView>
  );
});

export default ManagementView;
