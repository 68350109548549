import { observer } from "mobx-react";
import BaseView from "wes_shell_app/base-view";
import MonitoringDataTable from "../components/monitoring-data-table";
import { MonitoringDataTableFilter } from "../components/monitoring-data-table-fitler";

const MonitoringView = observer(() => {
  return (
    <BaseView>
      <MonitoringDataTableFilter />
      <MonitoringDataTable />
    </BaseView>
  );
});

export default MonitoringView;
