import BaseTableViewStore from "wes_shell_app/base-table-view-store";
import { IRequestFilterParams } from "../utils/request-utils";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { makeObservable } from "mobx";
import { IServerAPIRequestModel } from "models/server-models";
import { serverToClientRequestModel } from "models/mappers";
import { IClientRequestModel } from "models/client-models";
import { RequestDataTableProvider } from "./data/request-data-table-provider";
import { deleteItem } from "wes_shell_app/api-utils";
import { environment } from "environment";
import alerts from "wes_shell_app/alerts";

export class RequestViewStore extends BaseTableViewStore<
  IServerAPIRequestModel,
  IClientRequestModel,
  IRequestFilterParams,
  RequestDataTableProvider
> {
  constructor() {
    super(new RequestDataTableProvider());
    makeObservable(this, {});
  }

  private readonly appContext = new SharedContextStore();

  deleteRequest = async (id: number) => {
    try {
      await deleteItem(environment.serviceApi + this.tableProvider.url, id);
      this.forceReload();
    } catch (e) {
      const fixedErrorMessage = (e.message as string).replace("Error: ", "");
      const errorModel = JSON.parse(fixedErrorMessage);
      alerts.error({ message: errorModel.message });
    }
  };

  mapServerToClientModel = (item): IClientRequestModel =>
    serverToClientRequestModel(item);

  get currentUserStore() {
    return this.appContext.appContext.accessRights;
  }
  get isAdmin() {
    return this.currentUserStore.isAdminRole || false;
  }
  get isLoaded() {
    return this.currentUserStore.isLoaded && this.tableProvider.isLoaded;
  }
  get defaultTimeFromValue() {
    return this.tableProvider.defaultTimeFromValue;
  }
}
