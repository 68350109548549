import { environment } from "environment";
import { IMonitoringFilterParams } from "features/monitoring/utils/monitoring-utils";
import { IServerAPIMonitoringModel } from "models/server-models";
import DataTableStore from "wes_shell_app/data-table-store";
import SharedContextStore from "wes_shell_app/shared-context-store";

export class MonitoringDataTableProvider extends DataTableStore<
  IServerAPIMonitoringModel,
  IMonitoringFilterParams
> {
  readonly sharedContextStore = new SharedContextStore();

  get endpointUrl(): string {
    return `ui/storageUnitMonitoring/${this.sharedContextStore.appContext.currentStationId}`;
  }
  protected serviceApiUrl = environment.serviceApi;

  get defaultFilter() {
    return new Map<any, any>([
      ["timeFrom", this.defaultTimeFromValue.toISOString()],
      ["active", "true"],
    ]);
  }

  get defaultTimeFromValue() {
    let timeFrom = new Date();
    timeFrom.setHours(timeFrom.getHours() - 1);
    return timeFrom;
  }
}
