import { useRequestDetailStore } from "features/request/hooks/use-request-detail-store";
import { observer } from "mobx-react";
import Big from "wes_shell_app/big";
import { Drawer } from "wes_shell_app/drawer";
import { getTranslation } from "wes_shell_app/localization-utils";
import RequestDetailDataTable from "./request-detail-data-table";

const RequestDetailDrawer = observer(() => {
  const store = useRequestDetailStore();

  return (
    <Drawer
      anchor="right"
      onClose={store.closeDrawer}
      open={store.isDrawerOpen}
      sx={{ width: "40%" }}
    >
      <div>
        <Big weight="bold" sx={{ marginBottom: "1rem" }}>{`${getTranslation(
          "sortPlan"
        )}: ${store.request?.name}`}</Big>
      </div>
      <RequestDetailDataTable />
    </Drawer>
  );
});

export default RequestDetailDrawer;
