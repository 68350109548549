import BaseTableViewStore from "wes_shell_app/base-table-view-store";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { makeObservable, observable } from "mobx";
import { IServerAPIRequestDetailModel } from "models/server-models";
import { serverToClientRequestModel } from "models/mappers";
import {
  IClientRequestDetailModel,
  IClientRequestModel,
} from "models/client-models";
import { deleteItem } from "wes_shell_app/api-utils";
import { environment } from "environment";
import alerts from "wes_shell_app/alerts";
import { IRequestDetailFilterParams } from "../utils/request-detail-utils";
import { RequestDetailDataTableProvider } from "./data/request-detail-data-table-provider";

export class RequestDetailStore extends BaseTableViewStore<
  IServerAPIRequestDetailModel,
  IClientRequestDetailModel,
  IRequestDetailFilterParams,
  RequestDetailDataTableProvider
> {
  constructor() {
    super(new RequestDetailDataTableProvider());
    makeObservable(this, {
      drawer: observable,
    });
  }

  private readonly appContext = new SharedContextStore();

  request: IClientRequestModel | null = null;
  drawer: boolean = false;

  openDrawer = () => {
    this.drawer = true;
  };
  closeDrawer = () => {
    this.resetRequest();
    this.drawer = false;
  };

  get isDrawerOpen() {
    return this.drawer;
  }

  set setRequest(request: IClientRequestModel) {
    this.tableProvider.selectedRequest = request;
    this.request = request;
  }

  resetRequest = () => {
    this.request = null;
  };

  deleteRequestDetail = async (id: string) => {
    try {
      await deleteItem(environment.serviceApi + this.tableProvider.url, id);
      this.forceReload();
    } catch (e) {
      const fixedErrorMessage = (e.message as string).replace("Error: ", "");
      const errorModel = JSON.parse(fixedErrorMessage);
      alerts.error({ message: errorModel.message });
    }
  };

  get currentUserStore() {
    return this.appContext.appContext.accessRights;
  }
  get isAdmin() {
    return this.currentUserStore.isAdminRole || false;
  }
  get isLoaded() {
    return this.currentUserStore.isLoaded && this.tableProvider.isLoaded;
  }
}
