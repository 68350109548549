import { observer } from "mobx-react";
import { useEffect } from "react";
import { useDashboardStore } from "../hooks/use-dashboard-store";
import { SimpleCard } from "wes_shell_app/simpleCard";
import KpiCharts from "common/base-components/kpi/kpi-chart";
import SimplePieChart from "wes_shell_app/pie-chart";
import { getTranslation } from "wes_shell_app/localization-utils";
import KPI from "common/base-components/kpi/kpi";
import { AllInbox, WidthFull, WidthFullOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";

const DashboardHeader = observer(() => {
  const store = useDashboardStore();

  useEffect(() => {
    store.openConnection();

    return () => {
      store.closeConnection();
    };
  }, [store]);

  if (!store.isLoaded) return null;

  return (
    <>
      <div className="flex w-full gap-2 flex-wrap">
        <SimpleCard topMargin={false} className="flex-1">
          <KpiCharts
            chart={
              <SimplePieChart
                currentCount={12} // TODO MIJN change to correct value
                maxCount={21} // TODO MIJN change to correct value
                labels={[
                  getTranslation("retrieved"),
                  getTranslation("remaining"),
                ]}
                colors={["000", "rgb(219 219 219)"]}
                tooltip={/* 12 ? false :  */ true} // TODO MIJN Check sorter what tooltip is needed
              />
            }
            intlId="orders"
            value={[12, 21]} // TODO MIJN change to correct value
            description={[getTranslation("retrieved"), getTranslation("total")]}
          ></KpiCharts>
        </SimpleCard>
        <SimpleCard topMargin={false} className="flex-1">
          <KpiCharts
            chart={
              <SimplePieChart
                currentCount={12} // TODO MIJN change to correct value
                maxCount={32} // TODO MIJN change to correct value
                labels={[
                  getTranslation("retrieved"),
                  getTranslation("remaining"),
                ]}
                colors={["000", "rgb(219 219 219)"]}
                tooltip={/* 12 ? false :  */ true} // TODO MIJN Check sorter what tooltip is needed
              />
            }
            intlId="numberOfPickedItem"
            value={[12, 32]} // TODO MIJN change to correct value
            description={[getTranslation("retrieved"), getTranslation("total")]}
          ></KpiCharts>
        </SimpleCard>
        <SimpleCard topMargin={false} className="flex-1">
          <KpiCharts
            chart={
              <SimplePieChart
                currentCount={12} // TODO MIJN change to correct value
                maxCount={32} // TODO MIJN change to correct value
                labels={[
                  getTranslation("retrieved"),
                  getTranslation("remaining"),
                ]}
                colors={["000", "rgb(219 219 219)"]}
                tooltip={/* 12 ? false :  */ true} // TODO MIJN Check sorter what tooltip is needed
              />
            }
            intlId="numberOfPickedSKU"
            value={[12, 32]} // TODO MIJN change to correct value
            description={[getTranslation("retrieved"), getTranslation("total")]}
          ></KpiCharts>
        </SimpleCard>
        <Box className="flex-1">
          <SimpleCard
            topMargin={false}
            className="grid grid-cols-2 gap-2 h-full"
          >
            <div className="flex flex-col gap-2">
              <KPI
                icon={
                  <WidthFullOutlined
                    fontSize="large"
                    color={24 > 0 ? "primary" : "inherit"}
                  />
                }
                intlId="numberOfUnpickedSKU"
              >
                {24}
              </KPI>
              <KPI
                icon={
                  <WidthFull
                    fontSize="large"
                    color={24 > 0 ? "primary" : "inherit"}
                  />
                }
                intlId="numberOfFilledSKU"
              >
                {24}
              </KPI>
            </div>
            <div className="flex justify-center items-start">
              <KPI
                icon={
                  <AllInbox
                    fontSize="large"
                    color={24 > 0 ? "primary" : "inherit"}
                  />
                }
                intlId="numberOfFilledCrates"
              >
                {24}
              </KPI>
            </div>
          </SimpleCard>
        </Box>
      </div>
    </>
  );
});

export default DashboardHeader;
