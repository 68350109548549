/**
 * Mapps data comming from server API to client models
 */

import * as serverModels from "./server-models";
import * as clientModels from "./client-models";
import formatters from "wes_shell_app/localization-utils";

export const serverToClientHeaderInfoModel = (
  item: serverModels.IWebSocketHeaderInfoModel
): clientModels.IClientHeaderInfoModel => ({
  ...item,
  itemsFullnessArray: [item.sortedItems, item.totalItems],
  ordersFullnessArray: [item.sortedOrders, item.totalOrders],
  bufferFullnessArray: [item.bufferItems],
  hospitalFullnessArray: [item.hospitalItems],
  headerInfoStartedDate: item.started
    ? formatters.formatDate(item.started)
    : "-",
  waveID: item.id ? item.id : "-",
});

export const serverToClientFatalTableMessageContent = (
  item: serverModels.IWebsocketTableMessagesServerModelContent,
  clientModule: string
): clientModels.IWebsocketFatalTableMessagesClientModelContent => ({
  ...item,
  appmoduleName: clientModule || "--",
  warehouseId: 0,
  warehouseName: "",
  stationId: 0,
  stationName: "",
  applicationUuid: "",
  applicationName: "",
  type: "",
});

export const serverToClientTableMessageContent = (
  item: serverModels.IWebsocketTableMessagesServerModelContent,
  clientModule: string
): clientModels.IWebsocketTableMessagesClientModelContent => ({
  ...item,
  appmoduleName: clientModule || "--",
});

export const serverToClientRequestModel = (
  item: serverModels.IServerAPIRequestModel
): clientModels.IClientRequestModel => ({
  ...item,
  created: formatters.formatDateTime(item.created),
  accepted: formatters.formatDateTime(item.accepted),
  completed: formatters.formatDateTime(item.completed),
});

export const serverToClientManagementStorageUnitModel = (
  item: serverModels.IServerAPIManagementStorageUnitModel
): clientModels.IClientManagementStorageUnitModel => ({
  ...item,
  created: formatters.formatDateTime(item.created),
  lastSeen: formatters.formatDateTime(item.lastSeen),
  updated: formatters.formatDateTime(item.updated),
});

export const serverToClientMonitoringModel = (
  item: serverModels.IServerAPIMonitoringModel
): clientModels.IClientMonitoringModel => ({
  ...item,
  station: `(${item.stationId}) - ${item.stationName}`,
  created: formatters.formatDateTime(item.created),
});
