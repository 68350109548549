import BaseTableViewStore from "wes_shell_app/base-table-view-store";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { makeObservable } from "mobx";
import { IServerAPIMonitoringModel } from "models/server-models";
import { IMonitoringFilterParams } from "../utils/monitoring-utils";
import { IClientMonitoringModel } from "models/client-models";
import { MonitoringDataTableProvider } from "./provider/monitoring-provider";
import { serverToClientMonitoringModel } from "models/mappers";

export class MonitoringViewStore extends BaseTableViewStore<
  IServerAPIMonitoringModel,
  IClientMonitoringModel,
  IMonitoringFilterParams,
  MonitoringDataTableProvider
> {
  constructor() {
    super(new MonitoringDataTableProvider());
    makeObservable(this, {});
  }

  private readonly appContext = new SharedContextStore();

  mapServerToClientModel = (item): IClientMonitoringModel =>
    serverToClientMonitoringModel(item);

  get currentUserStore() {
    return this.appContext.appContext.accessRights;
  }
  get isAdmin() {
    return this.currentUserStore.isAdminRole || false;
  }
  get isLoaded() {
    return this.currentUserStore.isLoaded && this.tableProvider.isLoaded;
  }

  get defaultTimeFromValue() {
    return this.tableProvider.defaultTimeFromValue;
  }
}
