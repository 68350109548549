import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import DataTable, { IDataTableHeaderCellProps } from "wes_shell_app/data-table";
import { useManagementStore } from "../hooks/use-management-store";
import { IClientManagementStorageUnitModel } from "models/client-models";
import { Box, Collapse, TableCell, TableRow } from "@mui/material";
import Button from "wes_shell_app/button";
import {
  Delete,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import Title from "wes_shell_app/title";
import ManagementSlotDataTable from "./management-slot-data-table";
import { ManagementSlotTableStore } from "../stores/management-slot-table-store";
import { getTranslation } from "wes_shell_app/localization-utils";
import ManagementSuDeleteDialog from "./dialogs/management-su-delete-dialog";

const columns: IDataTableHeaderCellProps[] = [
  {
    label: "",
  },
  {
    label: "id",
  },
  {
    label: "state",
  },
  {
    label: "barcode",
  },
  {
    label: "stationId",
  },
  {
    label: "location",
  },
  {
    label: "lastSeen",
  },
  {
    label: "created",
  },
  {
    label: "updated",
  },
  {
    label: "",
  },
];

const ManagementSUDataTable = observer(() => {
  const store = useManagementStore();

  const handleDelete = async (id: number) => {
    await store.deleteStorageUnit(id);
  };

  return (
    <DataTable
      page={store.page}
      isLoading={!store.isLoaded}
      onPageChange={store.setPage}
      activeSortProperty={store.sortBy}
      onSortChange={store.setSort}
      onDirectionChange={store.setDirection}
      direction={store.direction}
      pageCount={store.totalCount}
      rows={store.items}
      pageSize={store.pageSize}
      onPageSizeChange={store.setPageSize}
      columns={columns}
      rowTemplate={(item) => (
        <RowTemplate
          key={item.id}
          item={item}
          isAdmin={store.isAdmin}
          handleDelete={handleDelete}
        />
      )}
    />
  );
});

type IRowTemplateProps = {
  item: IClientManagementStorageUnitModel;
  isAdmin?: boolean;
  handleDelete: (id: number) => void;
};

const RowTemplate = observer((props: IRowTemplateProps) => {
  const [open, setOpen] = useState(false);
  const { item, isAdmin, handleDelete } = props;
  const {
    id,
    active,
    barcode,
    stationId,
    location,
    lastSeen,
    created,
    updated,
  } = item;

  const slotStoreRef = React.useRef(new ManagementSlotTableStore());
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    open && slotStoreRef.current.forceReload();
  }, [open, id]);

  const handleRowClick = () => {
    slotStoreRef.current.providerSuId = id;
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <ManagementSuDeleteDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        item={item}
        deleteSu={handleDelete}
      />
      <TableRow>
        <TableCell>
          <Button
            variant="icon"
            icon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            onClick={handleRowClick}
          />
        </TableCell>
        <TableCell>{id}</TableCell>
        <TableCell>
          {active ? getTranslation("active") : getTranslation("inactive")}
        </TableCell>
        <TableCell>{barcode}</TableCell>
        <TableCell>{stationId}</TableCell>
        <TableCell>{location}</TableCell>
        <TableCell>{lastSeen}</TableCell>
        <TableCell>{created}</TableCell>
        <TableCell>{updated}</TableCell>
        {isAdmin && item.active === true && (
          <TableCell>
            <Button
              variant="icon"
              icon={<Delete color="error" />}
              onClick={() => setOpenDeleteDialog(true)}
            />
          </TableCell>
        )}
      </TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
        <Collapse in={open} timeout="auto" unmountOnExit sx={{ width: "100%" }}>
          <Box className="flex justify-between mt-5 mb-2 align-bottom">
            <Title intlId="slots" sx={{ marginBottom: "0px" }} />
          </Box>
          <Box
            sx={{
              border: "1px solid #e0e0e0",
              margin: "1.5rem 0 1.5rem 1rem",
              position: "relative",
            }}
          >
            <ManagementSlotDataTable store={slotStoreRef.current} />
          </Box>
        </Collapse>
      </TableCell>
    </React.Fragment>
  );
});

export default ManagementSUDataTable;
