import { Box } from "@mui/material";
import { useManagementStore } from "../hooks/use-management-store";
import DateTimePicker from "wes_shell_app/date-time-picker";
import TextInputField from "wes_shell_app/text-input-field";
import { FilterSelector } from "common/common-components/selectors/filter-selector";
import { StateItems } from "../utils/management-utils";
import { observer } from "mobx-react";
import Button from "wes_shell_app/button";
import { ManagementSuDialog } from "./dialogs/management-su-dialog";

export const ManagementSuDataTableFilter = observer(() => {
  const store = useManagementStore();

  return (
    <Box className="flex justify-between flex-wrap w-full gap-2 mt-2 mb-8">
      <Box className="flex flex-row gap-2 flex-wrap grow">
        <Box className="w-48">
          <FilterSelector
            intld={"state"}
            placeholder={"state"}
            value={store.getFilterValue("active")}
            items={StateItems}
            onChange={(v) =>
              store.setFilter("active", v === "active" ? true : false)
            }
            onClear={() => store.clearFilter("active")}
          />
        </Box>
        <Box className="w-56">
          <DateTimePicker
            intlId="timeFrom"
            value={store.getFilterValue("timeFrom")}
            onConfirm={(v) => store.setDateFilterValue("timeFrom", v)}
            defaultValue={store.defaultTimeFromValue}
          />
        </Box>
        <Box className="w-56">
          <DateTimePicker
            intlId="timeTo"
            value={store.getFilterValue("timeTo")}
            onConfirm={(v) => store.setDateFilterValue("timeTo", v)}
          />
        </Box>
        <Box>
          <TextInputField
            label="barcode"
            value={store.getFilterValue("barcode")}
            onChange={(v) => store.setFilter("barcode", v)}
          />
        </Box>
        <Box>
          <TextInputField
            label="contentBarcode"
            value={store.getFilterValue("barcodeContent")}
            onChange={(v) => store.setFilter("barcodeContent", v)}
          />
        </Box>
        <Box className="flex items-center ml-auto">
          <ManagementSuDialog />
          <Button
            onClick={() => store.showDialog()}
            intlId="newSu"
            variant="contained"
          />
        </Box>
      </Box>
    </Box>
  );
});
