import { makeAutoObservable } from "mobx";
import { parse } from "cookie";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { environment } from "environment";
import { IWebsocketMessageModel, IWebsocketTableMessagesModel, WebsocketMessageType } from "models/server-models";
import { IWebsocketFatalTableMessagesClientModelContent, IWebsocketTableMessagesClientModelContent } from "models/client-models";
import { serverToClientFatalTableMessageContent, serverToClientHeaderInfoModel, serverToClientTableMessageContent } from "models/mappers";

const TOKEN = "wes-token";

interface IAuthTokens {
  accessToken: string;
}

export const getToken = (): IAuthTokens => ({
  accessToken: parse(document.cookie)?.[TOKEN],
});

export class DashboardViewStore {
  private readonly sharedContext: SharedContextStore = new SharedContextStore();

  constructor() {
    makeAutoObservable(this);
  }

  fatalMessagesCount = 0;
  headerInfoName = "-";
  waveID = "-";
  headerInfoState = "-";
  headerInfoStartedDate = "-";
  duration = 0;
  waveControlResponseText: string = "";

  fatalMessagesDataTable: IWebsocketFatalTableMessagesClientModelContent[] = [];
  processMessagesDataTable: IWebsocketTableMessagesClientModelContent[] = [];
  technologyMessagesDataTable: IWebsocketTableMessagesClientModelContent[] = [];

  setFatalMessagesCount = (v: number) => (this.fatalMessagesCount = v);
  setFatalMessagesTableData = (v: IWebsocketTableMessagesModel) => {
    this.fatalMessagesDataTable = v.content.map(x => serverToClientFatalTableMessageContent(x, this.getModule(x.appmoduleUuid)?.name));
  }
  setProcessMessagesTableData = (v: IWebsocketTableMessagesModel) => {
    this.processMessagesDataTable = v.content.map(x => serverToClientTableMessageContent(x, this.getModule(x.appmoduleUuid)?.name));
  }
  setTechnologyMessagesTableData = (v: IWebsocketTableMessagesModel) => {
    this.technologyMessagesDataTable = v.content.map(x => serverToClientTableMessageContent(x, this.getModule(x.appmoduleUuid)?.name));
  }
  setHeaderInfoName = (v: string) => (this.headerInfoName = v);
  setWaveID = (v: string | number) => (this.waveID = String(v));
  setHeaderInfoState = (v: string) => (this.headerInfoState = v);
  setHeaderInfoStartedDate = (v: string) => (this.headerInfoStartedDate = v);
  setDuration = (v: number) => (this.duration = v);
  setWaveControlResponseText = (v: string) => (this.waveControlResponseText = v);

  //websockets
  wsConnection: WebSocket;
  timeout;

  get isLoaded() { return this.sharedContext.appContext.isContextLoaded; }
  private getModule = (uuid: string) => this.sharedContext.appContext.getModule(uuid);

  private getClientIpAddress = async () => {
    try {
      const response = await fetch("https://ipapi.co/json");
      const result = await response.json();
      return result.ip;
    } catch (e) { }
  };

  openConnection = async () => {
    console.log("openConnection");
    const clientIpAddress = await this.getClientIpAddress();
    this.wsConnection = new WebSocket(
      `${environment.dashboardWs}${this.sharedContext.appContext.currentStationId}`
    );
    const authMessage = {
      message: "login",
      client: clientIpAddress,
      jwt: getToken().accessToken,
    };

    if (this.wsConnection.readyState === 1) {
      this.wsConnection.send(JSON.stringify(authMessage));
    }

    this.wsConnection.onopen = () => {
      console.log("this.wsConnection.onopen");
      this.wsConnection.send(JSON.stringify(authMessage));
    };

    this.wsConnection.onerror = async (event) => {
      console.log("error", event);
    };

    this.wsConnection.onmessage = async (event) => {
      console.log("this.wsConnection.onmessage");
      // first check if connection is live
      if (this.wsConnection.readyState === 1) {
        const data: IWebsocketMessageModel = JSON.parse(event.data);
        console.log(data);
        if (data.messageType === WebsocketMessageType.fatal) {
          const { messageCount, message } = data;
          this.setFatalMessagesCount(messageCount);
          this.setFatalMessagesTableData(message);
        } else if (data.messageType === WebsocketMessageType.process) {
          this.setProcessMessagesTableData(data.message);
        } else if (data.messageType === WebsocketMessageType.technical) {
          this.setTechnologyMessagesTableData(data.message);
        } else if (data.messageType === WebsocketMessageType.headerInfo) {
          const { name, waveID, state, headerInfoStartedDate, duration } = serverToClientHeaderInfoModel(data.message[0]);;
          this.setHeaderInfoName(name);
          this.setWaveID(waveID);
          this.setHeaderInfoState(state);
          this.setHeaderInfoStartedDate(headerInfoStartedDate);
          this.setDuration(duration);
        }
      }
    }
  }

  closeConnection = () => {
    if (this.wsConnection) {
      this.wsConnection.close();
    }
  };
}
