import SharedContextStore from "wes_shell_app/shared-context-store";
import { action, makeObservable, observable } from "mobx";
import { IServerAPIManagementStorageUnitModel } from "models/server-models";
import { ManagementDataTableProvider } from "./provider/management-data-table-provider";
import { IClientManagementStorageUnitModel } from "models/client-models";
import { IManagementFilterParams } from "../utils/management-utils";
import BaseTableViewStore from "wes_shell_app/base-table-view-store";
import { serverToClientManagementStorageUnitModel } from "models/mappers";
import { dataPOST, deleteItem } from "wes_shell_app/api-utils";
import { environment } from "environment";
import alerts from "wes_shell_app/alerts";

type newSlot = {
  barcode: string;
  address: number;
};

export class ManagementTableStore extends BaseTableViewStore<
  IServerAPIManagementStorageUnitModel,
  IClientManagementStorageUnitModel,
  IManagementFilterParams,
  ManagementDataTableProvider
> {
  constructor() {
    super(new ManagementDataTableProvider());
    makeObservable(this, {
      suBarcode: observable,
      numberOfSlots: observable,
      newSlots: observable,
      dialog: observable,
      setSuBarcode: action,
      setNumberOfSlots: action,
      addNewSlot: action,
      resetModelValues: action,
    });
  }

  suBarcode: string = "";
  numberOfSlots: number = 0;
  newSlots = observable.map<string, newSlot>();
  dialog = false;

  showDialog = () => {
    this.dialog = true;
  };

  hideDialog = () => {
    this.dialog = false;
  };

  setSuBarcode = (value: string) => {
    this.suBarcode = value;
  };

  setNumberOfSlots = (value: number) => {
    this.numberOfSlots = value;

    if (value < this.newSlots.size) {
      Array.from(this.newSlots.keys())
        .slice(value)
        .forEach((key) => this.newSlots.delete(key));
    }
  };

  addNewSlot = (index: string, barcode: string, address: number | null) => {
    this.newSlots.set(index, { barcode, address: address ?? 0 });
  };

  resetModelValues = () => {
    this.suBarcode = "";
    this.numberOfSlots = 0;
    this.newSlots.clear();
  };

  deleteStorageUnit = async (id: number) => {
    try {
      await deleteItem(environment.serviceApi + this.tableProvider.url, id);
      this.forceReload();
    } catch (e) {
      const fixedErrorMessage = (e.message as string).replace("Error: ", "");
      const errorModel = JSON.parse(fixedErrorMessage);
      alerts.error({ message: errorModel.message });
    }
  };

  postNewStorageUnit = async () => {
    try {
      const newSlots = Array.from(this.newSlots.values()).map((slot) => ({
        barcode:
          this.numberOfSlots === 1
            ? this.suBarcode
            : slot.barcode /* If there is only one slot in the SU, the barcode of the slot is the SU barcode  */,
        address: slot.address,
      }));
      const model = {
        barcode: this.suBarcode,
        slots: newSlots,
      };
      await dataPOST(
        environment.serviceApi + this.tableProvider.endpointUrl,
        model
      );
      this.hideDialog();
      this.forceReload();
      this.resetModelValues();
    } catch (e) {
      const fixedErrorMessage = (e.message as string).replace("Error: ", "");
      const errorModel = JSON.parse(fixedErrorMessage);
      alerts.error({ message: errorModel.message });
      this.hideDialog();
      this.forceReload();
      this.resetModelValues();
    }
  };

  private readonly appContext = new SharedContextStore();

  mapServerToClientModel = (item): IClientManagementStorageUnitModel =>
    serverToClientManagementStorageUnitModel(item);

  get currentUserStore() {
    return this.appContext.appContext.accessRights;
  }
  get isAdmin() {
    return this.currentUserStore.isAdminRole || false;
  }
  get isLoaded() {
    return this.currentUserStore.isLoaded && this.tableProvider.isLoaded;
  }

  get defaultTimeFromValue() {
    return this.tableProvider.defaultTimeFromValue;
  }
}
