import { ManagementContentTableStore } from "features/management/stores/management-content-table-store";
import { observer } from "mobx-react";
import NumericInputField from "wes_shell_app/numeric-input-field";

type IManagementContentEditFormProps = {
  store: ManagementContentTableStore;
};

export const ManagementContentEditForm = observer(
  (props: IManagementContentEditFormProps) => {
    const { store } = props;

    return (
      <div className="flex flex-col justify-between space-y-4">
        <div className="flex gap-2">
          <NumericInputField
            required
            value={store.editDialogContent?.quantity}
            label="quantity"
            onChange={(v) => store.setPartialContent({ quantity: v })}
            min={0}
          />
        </div>
      </div>
    );
  }
);
