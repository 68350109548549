import { observer } from "mobx-react";
import { Dialog } from "wes_shell_app/dialog";
import { ManagementSuDialogForm } from "../forms/management-su-dialog-form";
import { useManagementStore } from "features/management/hooks/use-management-store";

export const ManagementSuDialog = observer(() => {
  const store = useManagementStore();

  const handleClose = () => {
    store.hideDialog();
    store.resetModelValues();
  };

  return (
    <Dialog
      open={store.dialog}
      intlTitle="newSu"
      confirmAction={store.postNewStorageUnit}
      cancelAction={handleClose}
    >
      <ManagementSuDialogForm />
    </Dialog>
  );
});
