import BaseView from "wes_shell_app/base-view";
import RequestDataTable from "../components/request/request-data-table";
import RequestDataTableFilter from "../components/request/request-data-table-filter";
import RequestDetailDrawer from "../components/detail/request-detail-drawer";

const RequestView = () => {
  return (
    <BaseView>
      <RequestDataTableFilter />
      <RequestDataTable />
      <RequestDetailDrawer />
    </BaseView>
  );
};

export default RequestView;
