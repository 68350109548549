import { useManagementStore } from "features/management/hooks/use-management-store";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import NumericInputField from "wes_shell_app/numeric-input-field";
import TextInputField from "wes_shell_app/text-input-field";
import Big from "wes_shell_app/big";
import { useFieldStore } from "wes_shell_app/use-field-store";

export const ManagementSuDialogForm = observer(() => {
  const store = useManagementStore();
  const fieldStore = useFieldStore();
  const [prevNumberOfSlots, setPrevNumberOfSlots] = useState(
    store.numberOfSlots
  );

  useEffect(() => {
    if (store.numberOfSlots < prevNumberOfSlots) {
      // When the number of slots is reduced, remove required data for the slots that were removed
      for (let i = store.numberOfSlots; i < prevNumberOfSlots; i++) {
        const requiredValueBarcode = `barcode${i}`;
        const requiredValueAddress = `address${i}`;
        fieldStore.removeFieldRequiredData(requiredValueBarcode); // Remove barcode required data
        fieldStore.removeFieldRequiredData(requiredValueAddress); // Remove address required data
      }
    }
    setPrevNumberOfSlots(store.numberOfSlots); // Update the previous number of slots
  }, [store.numberOfSlots, prevNumberOfSlots, fieldStore]);

  return (
    <div className="flex flex-col justify-between space-y-4">
      <div className="flex gap-2">
        <TextInputField
          required
          value={store.suBarcode}
          label="barcode"
          onChange={(v) => store.setSuBarcode(v)}
        />
        <NumericInputField
          required
          value={store.numberOfSlots}
          label="numberOfSlots"
          onChange={(v) => store.setNumberOfSlots(v)}
          min={0}
        />
      </div>
      {store.numberOfSlots > 0 && (
        <React.Fragment>
          <Big>Slots</Big>
        </React.Fragment>
      )}
      {Array.from({ length: store.numberOfSlots }).map((_, i) => {
        const slotKey = i.toString();
        return (
          <div key={i} className="flex gap-2">
            <TextInputField
              required
              requiredValue={`barcode${i}`}
              value={
                store.numberOfSlots === 1
                  ? store.suBarcode
                  : store.newSlots.get(slotKey)?.barcode || ""
              }
              label={"barcode"}
              onChange={(v) =>
                store.addNewSlot(
                  slotKey,
                  v,
                  store.newSlots.get(slotKey)?.address || 0
                )
              }
              readOnly={store.numberOfSlots === 1}
            />
            <NumericInputField
              required
              value={store.newSlots.get(slotKey)?.address || null}
              requiredValue={`address${i}`}
              label="address"
              onChange={(v) =>
                store.addNewSlot(
                  slotKey,
                  store.newSlots.get(slotKey)?.barcode || "",
                  v
                )
              }
            />
          </div>
        );
      })}
    </div>
  );
});
