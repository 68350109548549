import { TableCell, TableRow } from "@mui/material";
import { useRequestViewStore } from "features/request/hooks/use-request-store";
import { RequestViewStore } from "features/request/stores/request-view-store";
import { observer } from "mobx-react";
import { IClientRequestModel } from "models/client-models";
import Button from "wes_shell_app/button";
import DataTable, { IDataTableHeaderCellProps } from "wes_shell_app/data-table";
import { Delete, List } from "@mui/icons-material";
import { useRequestDetailStore } from "features/request/hooks/use-request-detail-store";

const columns: IDataTableHeaderCellProps[] = [
  {
    label: "id",
    sortKey: "id",
  },
  {
    label: "externalId",
    sortKey: "externalId",
  },
  {
    label: "type",
    sortKey: "type",
  },
  {
    label: "status",
    sortKey: "state",
  },
  {
    label: "name",
    sortKey: "name",
  },
  {
    label: "accepted",
    sortKey: "accepted",
  },
  {
    label: "created",
    sortKey: "created",
  },
  {
    label: "completed",
    sortKey: "completed",
  },
  {
    label: "lastTask",
    sortKey: "lastTask",
  },
  {
    label: "lastErrorCode",
    sortKey: "lastErrorCode",
  },
  {
    label: "lastErrorMessage",
    sortKey: "lastErrorMessage",
  },
];

const RequestDataTable = observer(() => {
  const store = useRequestViewStore();
  const detailStore = useRequestDetailStore();

  if (!store.isLoaded) return null;

  const handleDrawer = (request: IClientRequestModel) => {
    detailStore.setRequest = request;
    detailStore.forceReload();
    detailStore.openDrawer();
  };

  const handleDelete = async (id: number) => {
    await store.deleteRequest(id);
  };

  return (
    <DataTable
      page={store.page}
      isLoading={!store.isLoaded}
      onPageChange={store.setPage}
      activeSortProperty={store.sortBy}
      onSortChange={store.setSort}
      onDirectionChange={store.setDirection}
      direction={store.direction}
      pageCount={store.totalCount}
      rows={store.items}
      pageSize={store.pageSize}
      onPageSizeChange={store.setPageSize}
      columns={columns}
      rowTemplate={(item) => (
        <RowTemplate
          key={item.id}
          item={item}
          store={store}
          isAdmin={store.isAdmin}
          handleDrawer={handleDrawer}
          handleDelete={handleDelete}
        />
      )}
    />
  );
});

type IRowTemplateProps = {
  item: IClientRequestModel;
  store: RequestViewStore;
  isAdmin?: boolean;
  handleDrawer: (id: IClientRequestModel) => void;
  handleDelete: (id: number) => void;
};

const RowTemplate = observer((props: IRowTemplateProps) => {
  const { item, isAdmin, handleDrawer, handleDelete } = props;
  const {
    id,
    externalId,
    name,
    type,
    state,
    accepted,
    created,
    completed,
    lastTask,
    lastErrorCode,
    lastErrorMessage,
  } = item;

  return (
    <TableRow>
      <TableCell>{id}</TableCell>
      <TableCell>{externalId}</TableCell>
      <TableCell>{type}</TableCell>
      <TableCell>{state}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{accepted}</TableCell>
      <TableCell>{created}</TableCell>
      <TableCell>{completed}</TableCell>
      <TableCell>{lastTask}</TableCell>
      <TableCell>{lastErrorCode}</TableCell>
      <TableCell>{lastErrorMessage}</TableCell>
      <TableCell>
        <Button
          variant="icon"
          icon={<List color="primary" />}
          onClick={() => handleDrawer(item)}
        />
      </TableCell>
      <TableCell>
        {isAdmin && (item.state === "created" || item.state === "running") && (
          <Button
            variant="icon"
            icon={<Delete color="error" />}
            onClick={() => handleDelete(id)}
          />
        )}
      </TableCell>
    </TableRow>
  );
});

export default RequestDataTable;
