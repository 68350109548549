import { environment } from "environment";
import { IRequestFilterParams } from "features/request/utils/request-utils";
import DataTableStore from "wes_shell_app/data-table-store";
import SharedContextStore from "wes_shell_app/shared-context-store";

export class RequestDataTableProvider extends DataTableStore<
  any,
  IRequestFilterParams
> {
  readonly sharedContextStore = new SharedContextStore();

  get url(): string {
    return `ui/request/`;
  }

  get endpointUrl(): string {
    return `${this.url}${this.sharedContextStore.appContext.currentStationId}`;
  }
  protected serviceApiUrl = environment.serviceApi;

  get defaultFilter() {
    return new Map<any, any>([
      ["createdFrom", this.defaultTimeFromValue.toISOString()],
    ]);
  }

  get defaultTimeFromValue() {
    let timeFrom = new Date();
    timeFrom.setHours(timeFrom.getHours() - 1);
    return timeFrom;
  }
}
