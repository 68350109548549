import { SelectItemType } from "wes_shell_app/types";

export type IMonitoringFilterParams =
  | "timeFrom"
  | "timeTo"
  | "active"
  | "barcode"
  | "storageType"
  | "location"
  | "barcodeContent";

export const StateItems: SelectItemType[] = [
  {
    contentIntl: "active",
    id: "true",
  },
  {
    contentIntl: "inactive",
    id: "false",
  },
];

export const parseJSONString = (value: string): string => {
  let formattedText: string;

  try {
    const data = JSON.parse(value);

    formattedText = Object.entries(data)
      .map(([key, value]) => `${key}: ${value}`)
      .join(", ");

    return formattedText;
  } catch (e) {
    return value;
  }
};
