import { SelectItemType } from "wes_shell_app/types";

export type IRequestFilterParams =
  | "state"
  | "created"
  | "completed"
  | "type"
  | "name"
  | "externalId"
  | "lastTask"
  | "createdFrom"
  | "createdTo";

export const StateItems: SelectItemType[] = [
  {
    contentIntl: "created",
    id: "created",
  },
  {
    contentIntl: "completed",
    id: "completed",
  },
  {
    contentIntl: "deleted",
    id: "deleted",
  },
  {
    contentIntl: "running",
    id: "running",
  },
  {
    contentIntl: "cancelled",
    id: "cancelled",
  },
  {
    contentIntl: "error",
    id: "error",
  },
];

export const TypeItems: SelectItemType[] = [
  {
    contentIntl: "inbound",
    id: "inbound",
  },
  {
    contentIntl: "outbound",
    id: "outbound",
  },
];
