import React from "react";
import { Routes as ReactRoutes, Route } from "react-router-dom";
import { useRouting } from "wes_shell_app/use-routing";
import DashboardView from "features/dashboard/views/dashboard-view";
import RequestView from "features/request/views/request-view";
import { useNavigationStore } from "wes_shell_app/use-navigation-store";
import { observer } from "mobx-react";
import { Menu, RoutePaths, basePath } from "./menu";
import ManagementView from "features/management/views/management-view";
import MonitoringView from "features/monitoring/views/monitoring-view";

export const Routes = observer(() => {
  const navStore = useNavigationStore();
  const routerStore = useRouting();

  React.useEffect(() => {
    routerStore.addPaths([...Object.values(RoutePaths)], basePath);
    navStore.setItems(Menu().items);
  }, []);

  return (
    <ReactRoutes>
      <Route path={RoutePaths.home} element={<DashboardView />} />
      <Route path={RoutePaths.request} element={<RequestView />} />
      <Route path={RoutePaths.management} element={<ManagementView />} />
      <Route path={RoutePaths.monitoring} element={<MonitoringView />} />
    </ReactRoutes>
  );
});
