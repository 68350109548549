import { observer } from "mobx-react";
import { useMonitoringStore } from "../hooks/use-monitoring-store";
import { Box } from "@mui/material";
import { FilterSelector } from "common/common-components/selectors/filter-selector";
import DateTimePicker from "wes_shell_app/date-time-picker";
import TextInputField from "wes_shell_app/text-input-field";
import { StateItems } from "../utils/monitoring-utils";

export const MonitoringDataTableFilter = observer(() => {
  const store = useMonitoringStore();

  return (
    <Box className="flex justify-between flex-wrap w-full gap-2 mt-2 mb-8">
      <Box className="flex flex-row gap-2 flex-wrap grow">
        <Box className="w-44">
          <FilterSelector
            intld={"state"}
            placeholder={"state"}
            value={store.getFilterValue("active")}
            items={StateItems}
            onChange={(v) =>
              store.setFilter("active", v === "true" ? true : false)
            }
            onClear={() => store.clearFilter("active")}
          />
        </Box>
        <Box className="w-56">
          <DateTimePicker
            intlId="timeFrom"
            value={store.getFilterValue("timeFrom")}
            onConfirm={(v) => store.setDateFilterValue("timeFrom", v)}
            defaultValue={store.defaultTimeFromValue}
          />
        </Box>
        <Box className="w-56">
          <DateTimePicker
            intlId="timeTo"
            value={store.getFilterValue("timeTo")}
            onConfirm={(v) => store.setDateFilterValue("timeTo", v)}
          />
        </Box>
        <Box className="w-48">
          <TextInputField
            label="location"
            value={store.getFilterValue("location")}
            onChange={(v) => store.setFilter("location", v)}
          />
        </Box>
        <Box className="w-48">
          <TextInputField
            label="typeSu"
            value={store.getFilterValue("storageType")}
            onChange={(v) => store.setFilter("storageType", v)}
          />
        </Box>
        <Box className="w-48">
          <TextInputField
            label="barcode"
            value={store.getFilterValue("barcode")}
            onChange={(v) => store.setFilter("barcode", v)}
          />
        </Box>
        <Box className="w-48">
          <TextInputField
            label="contentBarcode"
            value={store.getFilterValue("barcodeContent")}
            onChange={(v) => store.setFilter("barcodeContent", v)}
          />
        </Box>
      </Box>
    </Box>
  );
});
