import { environment } from "environment";
import { IManagementFilterParams } from "features/management/utils/management-utils";
import { IServerAPIManagementStorageUnitModel } from "models/server-models";
import DataTableStore from "wes_shell_app/data-table-store";
import SharedContextStore from "wes_shell_app/shared-context-store";

export class ManagementDataTableProvider extends DataTableStore<
  IServerAPIManagementStorageUnitModel,
  IManagementFilterParams
> {
  readonly sharedContextStore = new SharedContextStore();

  protected serviceApiUrl = environment.serviceApi;

  get url(): string {
    return `ui/storageUnit`;
  }

  get endpointUrl(): string {
    return `${this.url}/${this.sharedContextStore.appContext.currentStationId}`;
  }

  get defaultFilter() {
    return new Map<any, any>([
      ["timeFrom", this.defaultTimeFromValue.toISOString()],
      ["active", "true"],
    ]);
  }

  get defaultTimeFromValue() {
    let timeFrom = new Date();
    timeFrom.setHours(timeFrom.getHours() - 1);
    return timeFrom;
  }
}
