import { Dialog } from "wes_shell_app/dialog";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import Text from "wes_shell_app/text";
import Title from "wes_shell_app/title";
import { Box } from "@mui/material";

export type SortPlansWaveControlProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  intlTitle: string;
  intlText: string;
  confirmLabel: string;
};

const DashboardWaveControlDialog = (props: SortPlansWaveControlProps) => {
  const { open, onClose, onConfirm, intlTitle, intlText, confirmLabel } = props;

  return (
    <Dialog
      open={open}
      intlTitle="waveControl"
      cancelAction={onClose}
      confirmAction={onConfirm}
      confirmLabel={confirmLabel}
    >
      <div className="flex flex-col gap-10 items-center">
        <ErrorOutlineOutlinedIcon
          sx={{ fontSize: 130, color: (theme) => theme.palette.grey[300] }}
        />
        <Box>
          <Title intlId={intlTitle} sx={{ textAlign: "center" }} />
          <Text intlId={intlText} sx={{ textAlign: "center" }} />
        </Box>
      </div>
    </Dialog>
  );
};

export default DashboardWaveControlDialog;
