import { TableCell, TableRow } from "@mui/material";
import { observer } from "mobx-react";
import { IClientRequestDetailModel } from "models/client-models";
import Button from "wes_shell_app/button";
import DataTable, { IDataTableHeaderCellProps } from "wes_shell_app/data-table";
import { Delete } from "@mui/icons-material";
import { RequestDetailStore } from "features/request/stores/request-detail-store";
import { useRequestDetailStore } from "features/request/hooks/use-request-detail-store";

const columnsProducts: IDataTableHeaderCellProps[] = [
  {
    label: "externalId",
    sortKey: "externalId",
  },
  {
    label: "name",
    sortKey: "name",
  },
  {
    label: "quantity",
  },
  {
    label: "unit",
  },
];

const columnsTexts: IDataTableHeaderCellProps[] = [
  {
    label: "externalId",
    sortKey: "externalId",
  },
  {
    label: "text",
  },
];

const RequestDetailDataTable = observer(() => {
  const store = useRequestDetailStore();

  if (!store.isLoaded) return null;

  const handleDelete = async (id: string) => {
    await store.deleteRequestDetail(id);
  };

  return (
    <DataTable
      page={store.page}
      isLoading={!store.isLoaded}
      activeSortProperty={store.sortBy}
      onSortChange={store.setSort}
      onDirectionChange={store.setDirection}
      direction={store.direction}
      rows={store.items}
      hidePaging={true}
      columns={
        store.request.detailType === "products" ? columnsProducts : columnsTexts
      }
      rowTemplate={(item) => (
        <RowTemplate
          key={item.externalId}
          item={item}
          store={store}
          isAdmin={store.isAdmin}
          handleDelete={handleDelete}
        />
      )}
    />
  );
});

type IRowTemplateProps = {
  item: IClientRequestDetailModel;
  store: RequestDetailStore;
  isAdmin?: boolean;
  handleDelete: (id: string) => void;
};

const RowTemplate = observer((props: IRowTemplateProps) => {
  const { item, store, isAdmin, handleDelete } = props;
  const { externalId, name, text, quantity, unit } = item;

  return (
    <TableRow>
      <TableCell>{externalId}</TableCell>
      {store.request.detailType === "products" ? (
        <>
          <TableCell>{name}</TableCell>
          <TableCell>{quantity}</TableCell>
          <TableCell>{unit}</TableCell>
        </>
      ) : (
        <TableCell>{text}</TableCell>
      )}
      <TableCell>
        {isAdmin && store.request.state === "created" && (
          <Button
            variant="icon"
            icon={<Delete color="error" />}
            onClick={() => handleDelete(item.externalId)}
          />
        )}
      </TableCell>
    </TableRow>
  );
});

export default RequestDetailDataTable;
