import React from "react";
import { observer } from "mobx-react";
import DataTable, { IDataTableHeaderCellProps } from "wes_shell_app/data-table";
import { IClientManagementContentModel } from "models/client-models";
import { TableCell, TableRow } from "@mui/material";
import Button from "wes_shell_app/button";
import { Edit } from "@mui/icons-material";
import { ManagementContentTableStore } from "../stores/management-content-table-store";
import { Dialog } from "wes_shell_app/dialog";
import { ManagementContentEditForm } from "./forms/management-content-edit-form";

const columns: IDataTableHeaderCellProps[] = [
  {
    label: "id",
  },
  {
    label: "suId",
  },
  {
    label: "slotId",
  },
  {
    label: "barcode",
  },
  {
    label: "quantity",
  },
  {
    label: "unit",
  },
  {
    label: "",
  },
];

type IManagementContentDataTableProps = {
  store: ManagementContentTableStore;
};

const ManagementContentDataTable = observer(
  (props: IManagementContentDataTableProps) => {
    const { store } = props;

    return (
      <>
        <Dialog
          open={store.showEditDialog}
          intlTitle="editContent"
          cancelAction={() => {
            store.setEditDialog(false);
          }}
          confirmAction={store.updateContent}
        >
          <ManagementContentEditForm store={store} />
        </Dialog>
        <DataTable
          isLoading={!store.isLoaded}
          onSortChange={store.setSort}
          rows={store.items}
          hidePaging
          columns={columns}
          rowTemplate={(item) => (
            <RowTemplate
              key={item.id}
              item={item}
              isAdmin={store.isAdmin}
              store={store}
            />
          )}
        />
      </>
    );
  }
);

type IRowTemplateProps = {
  item: IClientManagementContentModel;
  isAdmin?: boolean;
  store: ManagementContentTableStore;
};

const RowTemplate = observer((props: IRowTemplateProps) => {
  const { item, isAdmin, store } = props;
  const { id, suId, slotId, barcode, quantity, unit } = item;

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>{id}</TableCell>
        <TableCell>{suId}</TableCell>
        <TableCell>{slotId}</TableCell>
        <TableCell>{barcode}</TableCell>
        <TableCell>{quantity}</TableCell>
        <TableCell>{unit}</TableCell>
        {isAdmin && (
          <TableCell>
            <Button
              variant="icon"
              icon={<Edit color="primary" />}
              onClick={() => {
                store.setEditDialogContent(item);
                store.setEditDialog(true);
              }}
            />
          </TableCell>
        )}
      </TableRow>
    </React.Fragment>
  );
});

export default ManagementContentDataTable;
