import SharedContextStore from "wes_shell_app/shared-context-store";
import { makeObservable, observable } from "mobx";
import BaseTableViewStore from "wes_shell_app/base-table-view-store";
import { IServerAPIManagementContentModel } from "models/server-models";
import { IManagementContentFilterParams } from "../utils/management-utils";
import { ManagementContentDataTableProvider } from "./provider/management-content-data-table-provider";
import { IClientManagementContentModel } from "models/client-models";
import alerts from "wes_shell_app/alerts";
import { updateItem } from "wes_shell_app/api-utils";
import { environment } from "environment";

export class ManagementContentTableStore extends BaseTableViewStore<
  IServerAPIManagementContentModel,
  IClientManagementContentModel,
  IManagementContentFilterParams,
  ManagementContentDataTableProvider
> {
  constructor() {
    super(new ManagementContentDataTableProvider());
    makeObservable(this, {
      showEditDialog: observable,
      editDialogContent: observable,
    });
  }

  showEditDialog: boolean = false;
  editDialogContent: IClientManagementContentModel | null = null;

  setEditDialog = (value: boolean) => {
    this.showEditDialog = value;
  };

  setEditDialogContent = (value: IClientManagementContentModel | null) => {
    this.editDialogContent = value;
  };

  setPartialContent = (value: Partial<IClientManagementContentModel>) => {
    this.editDialogContent = { ...this.editDialogContent, ...value };
  };

  updateContent = async () => {
    if (this.editDialogContent) {
      try {
        await updateItem(
          `${environment.serviceApi}${this.tableProvider.url}${this.editDialogContent.id}`,
          { quantity: this.editDialogContent.quantity }
        );
        this.setEditDialogContent(null);
        this.forceReload();
        this.setEditDialog(false);
      } catch (e) {
        const fixedErrorMessage = (e.message as string).replace("Error: ", "");
        const errorModel = JSON.parse(fixedErrorMessage);
        alerts.error(errorModel.message);
      }
    }
  };

  private readonly appContext = new SharedContextStore();

  set providerSuId(slotId: number | string) {
    this.tableProvider.setSlotId(slotId);
  }

  get currentUserStore() {
    return this.appContext.appContext.accessRights;
  }
  get isAdmin() {
    return this.currentUserStore.isAdminRole || false;
  }
  get isLoaded() {
    return this.currentUserStore.isLoaded && this.tableProvider.isLoaded;
  }
}
