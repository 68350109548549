import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import DataTable, { IDataTableHeaderCellProps } from "wes_shell_app/data-table";
import { IClientManagementSlotModel } from "models/client-models";
import { Box, Collapse, TableCell, TableRow } from "@mui/material";
import Button from "wes_shell_app/button";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { ManagementSlotTableStore } from "../stores/management-slot-table-store";
import { ManagementContentTableStore } from "../stores/management-content-table-store";
import Title from "wes_shell_app/title";
import ManagementContentDataTable from "./management-content-data-table";

const columns: IDataTableHeaderCellProps[] = [
  {
    label: "",
  },
  {
    label: "id",
  },
  {
    label: "suId",
  },
  {
    label: "barcode",
  },
  {
    label: "address",
  },
];

type IManagementSlotDataTableProps = {
  store: ManagementSlotTableStore;
};

const ManagementSlotDataTable = observer(
  (props: IManagementSlotDataTableProps) => {
    const { store } = props;

    return (
      <DataTable
        isLoading={!store.isLoaded}
        onSortChange={store.setSort}
        rows={store.items}
        hidePaging
        columns={columns}
        rowTemplate={(item) => (
          <RowTemplate key={item.id} item={item} isAdmin={store.isAdmin} />
        )}
      />
    );
  }
);

type IRowTemplateProps = {
  item: IClientManagementSlotModel;
  isAdmin?: boolean;
};

const RowTemplate = observer((props: IRowTemplateProps) => {
  const [open, setOpen] = useState(false);
  const { item } = props;
  const { id, suId, barcode, address } = item;
  const contentStoreRef = React.useRef(new ManagementContentTableStore());

  useEffect(() => {
    open && contentStoreRef.current.forceReload();
  }, [open, id]);

  const handleRowClick = () => {
    contentStoreRef.current.providerSuId = id;
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <Button
            variant="icon"
            icon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            onClick={handleRowClick}
          />
        </TableCell>
        <TableCell>{id}</TableCell>
        <TableCell>{suId}</TableCell>
        <TableCell>{barcode}</TableCell>
        <TableCell>{address}</TableCell>
      </TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
        <Collapse in={open} timeout="auto" unmountOnExit sx={{ width: "100%" }}>
          <Box className="flex justify-between mt-5 mb-2 align-bottom">
            <Title intlId="content" sx={{ marginBottom: "0px" }} />
          </Box>
          <Box
            sx={{
              border: "1px solid #e0e0e0",
              margin: "1.5rem 0 1.5rem 1rem",
              position: "relative",
            }}
          >
            <ManagementContentDataTable store={contentStoreRef.current} />
          </Box>
        </Collapse>
      </TableCell>
    </React.Fragment>
  );
});

export default ManagementSlotDataTable;
