import React from "react";
import { observer } from "mobx-react";
import { getTranslation } from "wes_shell_app/localization-utils";
import { useDashboardStore } from "../hooks/use-dashboard-store";

const InfoRow = ({ label, value, className = "" }) => (
  <div className={`grid grid-cols-5 gap-1 ${className}`}>
    <div>{getTranslation(label)}:</div>
    <div className="font-medium col-start-3 col-span-5">{value}</div>
  </div>
);

function convertSecondsToTime(seconds: number) {
  if (!seconds) return "-";
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedTime = [
    hours.toString().padStart(2, "0"),
    minutes.toString().padStart(2, "0"),
    remainingSeconds.toString().padStart(2, "0"),
  ].join(":");

  return formattedTime;
}

const InfoWave = observer(() => {
  const store = useDashboardStore();

  if (!store.isLoaded) return null;

  const {
    headerInfoName,
    waveID,
    headerInfoState,
    headerInfoStartedDate,
    duration,
  } = store;

  return (
    <>
      <div className="grid grid-cols-1 gap-2">
        <InfoRow label="wave" value={headerInfoName} />
        <InfoRow label="ID" value={waveID} />
        <InfoRow label="status" value={headerInfoState} />
        <InfoRow label="startedAt" value={headerInfoStartedDate} />
        <InfoRow label="duration" value={convertSecondsToTime(duration)} />
      </div>
    </>
  );
});

export default InfoWave;
