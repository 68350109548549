import { environment } from "environment";
import { IRequestDetailFilterParams } from "features/request/utils/request-detail-utils";
import { makeObservable, observable } from "mobx";
import { IClientRequestModel } from "models/client-models";
import { IServerAPIRequestDetailModel } from "models/server-models";
import DataTableStore from "wes_shell_app/data-table-store";
import SharedContextStore from "wes_shell_app/shared-context-store";

export class RequestDetailDataTableProvider extends DataTableStore<
  IServerAPIRequestDetailModel,
  IRequestDetailFilterParams
> {
  selectedRequest: IClientRequestModel | null = null;

  constructor() {
    super();
    makeObservable(this, {
      selectedRequest: observable,
    });
  }

  readonly sharedContextStore = new SharedContextStore();

  get url(): string {
    return `ui/requestDetail`;
  }

  get endpointUrl(): string {
    return `${this.url}/${this.selectedRequest.id}`;
  }

  protected serviceApiUrl = environment.serviceApi;

  set setSelectRequest(selectedRequest: IClientRequestModel) {
    this.selectedRequest = selectedRequest;
  }
}
