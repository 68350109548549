import { Box } from "@mui/material";
import { FilterSelector } from "common/common-components/selectors/filter-selector";
import { useRequestViewStore } from "features/request/hooks/use-request-store";
import { StateItems, TypeItems } from "features/request/utils/request-utils";
import { observer } from "mobx-react";
import DateTimePicker from "wes_shell_app/date-time-picker";
import TextInputField from "wes_shell_app/text-input-field";

const RequestDataTableFilter = observer(() => {
  const store = useRequestViewStore();

  return (
    <Box className="flex flex-row justify-between space-x-2 w-full mt-2 mb-8">
      <Box className="flex flex-row w-full gap-2 flex-wrap">
        <Box className="w-56">
          <DateTimePicker
            intlId="createdFrom"
            value={store.getFilterValue("createdFrom")}
            onConfirm={(v) => store.setDateFilterValue("createdFrom", v)}
            defaultValue={store.defaultTimeFromValue}
          />
        </Box>
        <Box className="w-56">
          <DateTimePicker
            intlId="createdTo"
            value={store.getFilterValue("createdTo")}
            onConfirm={(v) => store.setDateFilterValue("createdTo", v)}
          />
        </Box>
        <Box className="w-56">
          <DateTimePicker
            intlId="timeCompleted"
            value={store.getFilterValue("completed")}
            onConfirm={(v) => store.setDateFilterValue("completed", v)}
          />
        </Box>
        <Box className="w-48">
          <FilterSelector
            intld={"status"}
            placeholder={"status"}
            value={store.getFilterValue("state")}
            items={StateItems}
            onChange={(v) => store.setFilter("state", v)}
            onClear={() => store.clearFilter("state")}
          />
        </Box>
        <Box className="w-40">
          <FilterSelector
            intld={"type"}
            placeholder={"type"}
            value={store.getFilterValue("type")}
            items={TypeItems}
            onChange={(v) => store.setFilter("type", v)}
            onClear={() => store.clearFilter("type")}
          />
        </Box>
        <Box className="w-40">
          <TextInputField
            label="name"
            value={store.getFilterValue("name")}
            onChange={(v) => store.setFilter("name", v)}
          />
        </Box>
        <Box className="w-24">
          <TextInputField
            label="externalId"
            value={store.getFilterValue("externalId")}
            onChange={(v) => store.setFilter("externalId", v)}
          />
        </Box>
        <Box className="w-48">
          <TextInputField
            label="lastTask"
            value={store.getFilterValue("lastTask")}
            onChange={(v) => store.setFilter("lastTask", v)}
          />
        </Box>
      </Box>
    </Box>
  );
});

export default RequestDataTableFilter;
