import { environment } from "environment";
import { makeObservable, observable } from "mobx";
import DataTableStore from "wes_shell_app/data-table-store";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { IServerAPIManagementSlotModel } from "models/server-models";
import { IManagementSlotFilterParams } from "features/management/utils/management-utils";

export class ManagementSlotDataTableProvider extends DataTableStore<
  IServerAPIManagementSlotModel,
  IManagementSlotFilterParams
> {
  static instanceCount = 0;
  instanceId: number;
  suId: number | string;

  constructor() {
    super();
    ManagementSlotDataTableProvider.instanceCount++;
    this.instanceId = ManagementSlotDataTableProvider.instanceCount;
    makeObservable(this, {
      suId: observable,
    });
  }

  setSuId(suId: number | string) {
    this.suId = suId;
  }

  readonly sharedContextStore = new SharedContextStore();

  protected serviceApiUrl = environment.serviceApi;

  get endpointUrl(): string {
    return `ui/slot/${this.suId}`;
  }
}
