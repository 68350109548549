import { environment } from "environment";
import { IManagementContentFilterParams } from "features/management/utils/management-utils";
import { makeObservable, observable } from "mobx";
import { IServerAPIManagementContentModel } from "models/server-models";
import DataTableStore from "wes_shell_app/data-table-store";
import SharedContextStore from "wes_shell_app/shared-context-store";

export class ManagementContentDataTableProvider extends DataTableStore<
  IServerAPIManagementContentModel,
  IManagementContentFilterParams
> {
  constructor() {
    super();
    makeObservable(this, {
      slotId: observable,
    });
  }

  slotId: number | string;

  setSlotId(slotId: number | string) {
    this.slotId = slotId;
  }

  readonly sharedContextStore = new SharedContextStore();

  protected serviceApiUrl = environment.serviceApi;

  get url(): string {
    return "ui/content/";
  }

  get endpointUrl(): string {
    return `${this.url}${this.slotId}`;
  }
}
