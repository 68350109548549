import { observer } from "mobx-react";
import { IClientManagementStorageUnitModel } from "models/client-models";
import { Dialog } from "wes_shell_app/dialog";
import Text from "wes_shell_app/text";

export interface ManagementSuDeleteDialogProps {
  item: IClientManagementStorageUnitModel;
  setOpen: (open: boolean) => void;
  open: boolean;
  deleteSu: (id: number) => void;
}

const ManagementSuDeleteDialog = observer(
  (props: ManagementSuDeleteDialogProps) => {
    const { item, setOpen, open, deleteSu } = props;

    return (
      <Dialog
        open={open}
        intlTitle="deleteRack"
        cancelAction={() => {
          setOpen(false);
        }}
        confirmAction={() => {
          deleteSu(props.item?.id);
          setOpen(false);
        }}
      >
        <Text
          intlId="SuConfirmDeleteSu"
          intlParams={{ suBarcode: item?.barcode }}
        />
      </Dialog>
    );
  }
);

export default ManagementSuDeleteDialog;
