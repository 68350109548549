import { observer } from "mobx-react";
import DataTable, { IDataTableHeaderCellProps } from "wes_shell_app/data-table";
import { useMonitoringStore } from "../hooks/use-monitoring-store";
import { IClientMonitoringModel } from "models/client-models";
import { TableCell, TableRow } from "@mui/material";
import { getTranslation } from "wes_shell_app/localization-utils";
import { parseJSONString } from "../utils/monitoring-utils";

const columns: IDataTableHeaderCellProps[] = [
  {
    label: "id",
  },
  {
    label: "suId",
  },
  {
    label: "slotId",
  },
  {
    label: "state",
  },
  {
    label: "typeSu",
  },
  {
    label: "location",
  },
  {
    label: "station",
  },
  {
    label: "barcode",
  },
  {
    label: "typeOfChange",
  },
  {
    label: "oldValue",
  },
  {
    label: "newValue",
  },
  {
    label: "created",
  },
];

const MonitoringDataTable = observer(() => {
  const store = useMonitoringStore();

  return (
    <DataTable
      page={store.page}
      isLoading={!store.isLoaded}
      onPageChange={store.setPage}
      activeSortProperty={store.sortBy}
      onSortChange={store.setSort}
      onDirectionChange={store.setDirection}
      direction={store.direction}
      pageCount={store.totalCount}
      rows={store.items}
      pageSize={store.pageSize}
      onPageSizeChange={store.setPageSize}
      columns={columns}
      rowTemplate={(item) => <RowTemplate key={item.id} item={item} />}
    />
  );
});

type IRowTemplateProps = {
  item: IClientMonitoringModel;
};

const RowTemplate = observer((props: IRowTemplateProps) => {
  const { item } = props;

  const {
    id,
    suId,
    slotId,
    storageType,
    station,
    active,
    location,
    barcode,
    typeOfChange,
    created,
  } = item;

  let { oldValue, newValue } = item;

  oldValue = parseJSONString(oldValue);
  newValue = parseJSONString(newValue);

  return (
    <TableRow>
      <TableCell>{id}</TableCell>
      <TableCell>{suId}</TableCell>
      <TableCell>{slotId}</TableCell>
      <TableCell>
        {active ? getTranslation("active") : getTranslation("inactive")}
      </TableCell>
      <TableCell>{storageType}</TableCell>
      <TableCell>{location}</TableCell>
      <TableCell>{station}</TableCell>
      <TableCell>{barcode}</TableCell>
      <TableCell>{typeOfChange}</TableCell>
      <TableCell>{oldValue}</TableCell>
      <TableCell>{newValue}</TableCell>
      <TableCell>{created}</TableCell>
    </TableRow>
  );
});

export default MonitoringDataTable;
