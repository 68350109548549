import { observer } from "mobx-react";
import BaseView from "wes_shell_app/base-view";
import DashboardHeader from "../components/dashboard-header";
import FatalDataTable from "../components/fatal-data-table";
import { SimpleCard } from "wes_shell_app/simpleCard";
import Title from "wes_shell_app/title";
import ProcessDataTable from "../components/process-data-table";
import TechnologyDataTable from "../components/technology-data-table";
import InfoWave from "../components/info-wave";
import Control from "../components/control";
import { WorkStation } from "../components/work-station";

const DashboardView = observer(() => {
  return (
    <BaseView cardBackgroundColor={false}>
      <DashboardHeader />
      <FatalDataTable />
      <div className="flex">
        <div className="w-full max-w-[80%]">
          <SimpleCard>
            <Title intlId="processTableMessagesLabel" />
            <ProcessDataTable />
          </SimpleCard>
          <SimpleCard>
            <Title intlId="technologyTableLabel" />
            <TechnologyDataTable />
          </SimpleCard>
        </div>
        <div className="pl-2 w-full max-w-[20%]">
          <SimpleCard>
            <Title intlId="waveInfo" />
            <InfoWave />
          </SimpleCard>
          <SimpleCard>
            <Title intlId="warehouseControl" />
            <Control />
          </SimpleCard>
          <SimpleCard>
            <Title intlId="workStation" />
            <WorkStation />
          </SimpleCard>
        </div>
      </div>
    </BaseView>
  );
});

export default DashboardView;
