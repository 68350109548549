export const basePath = "/goods-to-person";
export enum RoutePaths {
  home = "",
  request = "/request",
  management = "/management",
  monitoring = "/monitoring",
}

export function Menu() {
  return {
    items: [
      {
        title: "dashboard",
        link: basePath + RoutePaths.home,
        icon: "Dashboard",
      },
      {
        title: "monitoringSU",
        link: basePath + RoutePaths.monitoring,
        icon: "Dvr",
      },
      {
        title: "managementSU",
        link: basePath + RoutePaths.management,
        icon: "ManageSearch",
      },
      {
        title: "requestSU",
        link: basePath + RoutePaths.request,
        icon: "AssignmentReturn",
      },
    ],
  };
}
