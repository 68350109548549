/**
 * Models of all data comming from server via API.
 * Type declarations according to Swagger.
 */

import { IWithId } from "wes_shell_app/api-types";
import { Severity } from "wes_shell_app/types";

/* WSS started */
export enum WebsocketMessageType {
  headerInfo = "headerInfo",
  fatal = "fatal",
  process = "process",
  technical = "technical",
}

export type IWebsocketMessageModel =
  | IWebsocketFatalMessageModel
  | IWebsocketRobotUtilizationModelProcessMessage
  | IWebsocketRobotUtilizationModelTechnicalMessage
  | IWebsocketModelHeaderInfoMessage;

export type IWebSocketHeaderInfoModel = {
  id: number;
  sortedItems: number;
  totalItems: number;
  sortedOrders: number;
  totalOrders: number;
  bufferItems: number;
  hospitalItems: number;
  duration: number;
  name: string;
  started: Date;
  state: string;
};

export type IWebsocketTableMessagesServerModelContent = {
  id: number;
  issued: string;
  severity: Severity;
  confirmed: boolean;
  message: string;
  appmoduleUuid: string;
};

export type IWebsocketTableMessagesModel = {
  content: IWebsocketTableMessagesServerModelContent[];
};

export type IWebsocketFatalMessageModel = {
  message: IWebsocketTableMessagesModel;
  messageCount: number;
  messageType: WebsocketMessageType.fatal;
};

export type IWebsocketRobotUtilizationModelProcessMessage = {
  message: IWebsocketTableMessagesModel;
  messageType: WebsocketMessageType.process;
};

export type IWebsocketRobotUtilizationModelTechnicalMessage = {
  message: IWebsocketTableMessagesModel;
  messageType: WebsocketMessageType.technical;
};

export type IWebsocketModelHeaderInfoMessage = {
  message: IWebSocketHeaderInfoModel[];
  messageType: WebsocketMessageType.headerInfo;
};
/* WSS ended */

/* API started */
export type IServerAPIRequestModel = IWithId & {
  externalId: string;
  name: string;
  type: string;
  state: string;
  accepted: Date;
  created: Date;
  completed: Date;
  detailType: string;
  lastTask: number;
  lastErrorCode: number;
  lastErrorMessage: string;
};

export type IServerAPIRequestDetailModel = IWithId & {
  text?: string;
  name?: string;
  externalId?: string;
  quantity?: number;
  unit?: string;
};

export type IServerAPIManagementStorageUnitModel = IWithId & {
  active: Boolean;
  barcode: number;
  stationId: number;
  location: string;
  lastSeen: Date;
  created: Date;
  updated: Date;
};

export type IServerAPIManagementStorageUnitRequestModel = IWithId & {
  stationId: number;
  barcode: string;
  numberOfSlots: string;
  slots: IServerStorageUnitRequestSlotsModel[];
};

export type IServerStorageUnitRequestSlotsModel = {
  barcode: string;
  address: string;
};

export type IServerAPIManagementSlotModel = IWithId & {
  suId: number;
  barcode: number;
  address: number;
};

export type IServerAPIManagementContentModel = IWithId & {
  slotId: number;
  suId: number;
  barcode: number;
  quantity: number;
  unit: string;
};

export type IServerAPIMonitoringModel = IWithId & {
  suId: number;
  slotId: number;
  stationId: number;
  stationName: string;
  active: boolean;
  location: string;
  barcode: string;
  typeOfChange: string;
  storageType: string;
  oldValue: string;
  newValue: string;
  created: Date;
};

/* API ended */
