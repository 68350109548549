import SharedContextStore from "wes_shell_app/shared-context-store";
import { makeObservable } from "mobx";
import { IServerAPIManagementSlotModel } from "models/server-models";
import BaseTableViewStore from "wes_shell_app/base-table-view-store";
import { IManagementSlotFilterParams } from "../utils/management-utils";
import { IClientManagementSlotModel } from "models/client-models";
import { ManagementSlotDataTableProvider } from "./provider/management-slot-data-table-provider";

export class ManagementSlotTableStore extends BaseTableViewStore<
  IServerAPIManagementSlotModel,
  IClientManagementSlotModel,
  IManagementSlotFilterParams,
  ManagementSlotDataTableProvider
> {
  constructor() {
    super(new ManagementSlotDataTableProvider());
    makeObservable(this, {});
  }

  private readonly appContext = new SharedContextStore();

  set providerSuId(suId: number | string) {
    this.tableProvider.setSuId(suId);
  }

  get currentUserStore() {
    return this.appContext.appContext.accessRights;
  }
  get isAdmin() {
    return this.currentUserStore.isAdminRole || false;
  }
  get isLoaded() {
    return this.currentUserStore.isLoaded && this.tableProvider.isLoaded;
  }
}
