import { runInAction } from "mobx";
import { DashboardViewStore } from "features/dashboard/stores/dashboard-view-store";
import { RequestViewStore } from "features/request/stores/request-view-store";
import { RequestDetailStore } from "features/request/stores/request-detail-store";
import { ManagementTableStore } from "features/management/stores/management-table-store";
import { ManagementSlotTableStore } from "features/management/stores/management-slot-table-store";
import { ManagementContentTableStore } from "features/management/stores/management-content-table-store";
import { MonitoringViewStore } from "features/monitoring/stores/monitoring-view-store";

export class ViewStore {
  readonly dashboard = new DashboardViewStore();
  readonly request = new RequestViewStore();
  readonly management = new ManagementTableStore();
  readonly monitoring = new MonitoringViewStore();
}

export class CommonStore {
  readonly requestDetail = new RequestDetailStore();
  readonly managementSlot = new ManagementSlotTableStore();
  readonly managementContent = new ManagementContentTableStore();
}

export class RootStore {
  readonly view = new ViewStore();
  readonly common = new CommonStore();
}

export let rootStore = new RootStore();

export function initStores() {
  rootStore = runInAction(() => new RootStore());
}

export default rootStore;
