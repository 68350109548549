declare global {
  interface Window {
    _env_: any;
  }
}

const environmentVariables = window._env_;

export const environment = {
  serviceApi:
    environmentVariables.REACT_APP_GOODS_TO_PERSON_SERVICE_API,
  dashboardWs: environmentVariables.REACT_APP_GOODS_TO_PERSON_DASHBOARD_WSS,
};
