import { SelectItemType } from "wes_shell_app/types";

export type IManagementFilterParams =
  | "active"
  | "barcode"
  | "location"
  | "lastSeen"
  | "timeFrom"
  | "timeTo"
  | "barcodeContent";

export type IManagementSlotFilterParams = "";
export type IManagementContentFilterParams = "";

export const StateItems: SelectItemType[] = [
  {
    contentIntl: "active",
    id: "true",
  },
  {
    contentIntl: "inactive",
    id: "false",
  },
];
