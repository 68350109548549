import CardTable from "common/base-components/card/card-table";
import { observer } from "mobx-react";

export const WorkStation = observer(() => {
  return (
    <>
      <CardTable
        title={["Stanice 1", "Stanice 2", "Stanice 3", "Stanice 4"]}
        data={["Uzavřena", "Vychystává", "Uzavřena", "Doplňuje"]}
      />
    </>
  );
});
